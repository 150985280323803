@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@400&display=swap');
.terminal-wrapper {
	cursor: text;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background-color: #0d1116;
}

.command a {
	color: #dedee1;
}

.command p {
	margin: 0;
}

.terminal {
	background-color: #0e1030;
	color: #dedee1;
	font-family: 'Source Code Pro', monospace;
	font-size: 16px;
	width: 85%;
	max-width: 850px;
	height: 500px;
	border-radius: 10px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3), 0 10px 20px rgba(0, 0, 0, 0.4);
	position: relative;
	overflow-y: auto;
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	/* Ensures padding doesn't affect dimensions */
}

.terminal-header {
	display: flex;
	align-items: center;
	height: 24px;
	background-color: #3e4059;
	/* border-bottom: 1px solid #3e4059; */
	padding: 0 12px;
	box-sizing: border-box;
}

.terminal-body {
	flex: 1 1;
	padding: 16px;
	overflow-y: auto;
	border-bottom-left-radius: 10px;
	border-bottom-right-radius: 10px;
	border: 0.05rem solid #3e4059;
	border-top: 0;
}


.terminal-button {
	width: 12px;
	height: 12px;
	border-radius: 50%;
	margin-left: 3px;
	display: inline-block;
}

.terminal-button.close {
	background-color: #fe5f58;
}

.terminal-button.minimize {
	background-color: #ffbc2e;
}

.terminal-button.fullscreen {
	background-color: #29c740;
}

.terminal-line,
.terminal-input {
	display: flex;
	align-items: flex-start;
	white-space: pre-wrap;
	font-size: 16px;
	line-height: 20px;
	min-height: 20px;
}

.terminal-input {
	padding: 0;
	margin: 0;
	border: none;
	height: 20px;
}

.prompt {
	color: #89b15c;
	margin-right: 8px;
}

.directory {
	color: #4497a2;
	margin-right: 8px;
}

.input {
	background: transparent;
	border: none;
	outline: none;
	color: #dedee1;
	width: 100%;
	font-family: inherit;
	font-size: inherit;
	line-height: inherit;
	padding: 0;
	margin: 0;
	height: 20px;
	display: flex;
	align-items: center;
}